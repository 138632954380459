import React, { useState } from 'react'

import { graphql, Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from 'gatsby-image'

import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
    query($slug: String) {
        contentfulLesson (slug: {
            eq: $slug
        }) {
            title
            contributor {
                name
                image {
                    fluid {
                        ...GatsbyContentfulFluid
                    }
                }
            }
            date(formatString: "DD MMMM, YYYY")
            subjectTags
            type
            timescale
            body {
                json
            }
            images {
                fluid {
                    ...GatsbyContentfulFluid
                }
                file {
                    url
                }
            }
        }
    }
    `

const Lesson = (props) => {

    const { 
        title,
        contributor,
        date,
        subjectTags,
        type,
        timescale,
        body,
        images
     } = props.data.contentfulLesson

    const [ showLessonDetails, setShowLessonDetails ] = useState(true)

    let lessonBody = documentToReactComponents(body.json)

    return (
        <Layout>
            
            <SEO title={title} />

            <Link to="/decolonize-the-art-world" className="blogpost-back-btn is-size-4" state={{fromLesson: true}}>
                Back to courtyard
            </Link>

            <section className="section">
                <h1 className="title has-text-centered py-6 is-size-3-mobile">
                    {title}
                </h1>

                <div className="panel">
                    <div className="level panel-heading has-background-info py-3 px-3" 
                        onClick={() => setShowLessonDetails(!showLessonDetails)} 
                        onKeyPress={() => setShowLessonDetails(!showLessonDetails)} 
                        role="button" 
                        tabIndex={0}
                        style={{cursor: 'pointer'}}
                    >
                        <div className="level-left">
                            <h2 className="subtitle has-text-white is-size-3-mobile">
                                Lesson Details
                            </h2>
                        </div>

                        <div className="level-right">
                            <button className="hide-default-btn-styles" onClick={() => setShowLessonDetails(!showLessonDetails)}>
                                <span className="icon is-medium has-text-white is-size-2 level-right">
                                    {
                                        showLessonDetails ? 
                                            <FaCaretUp />
                                            :
                                            <FaCaretDown />
                                    }
                                </span>
                            </button>
                        </div>
                    </div>

                    {/* Panel Body */}
                    {
                        showLessonDetails &&
                            <div className="columns content is-vcentered mx-5 pb-3">
                                <div className="column is-half has-text-left">
                                    <div className="content">
                                        {
                                            contributor && contributor.map(({name, image}, index) => 

                                                <div key={index} className="pb-6 pl-0">
                                                    <p className="is-size-5">Contributor:</p>
                                                    { image &&
                                                        <figure className="image is-128x128 ml-0 mb-3">
                                                            <Img imgStyle={{borderRadius: '50%'}} fluid={image.fluid} alt="contributor image" />
                                                        </figure>
                                                    }
                                                    <p>{name}</p>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <p className="is-size-4 has-text-white pl-3" style={{background: '#4d3f56', borderRadius: '6px', width: '80%'}}>
                                        {date}
                                    </p>
                                </div>

                                <div className="column is-half has-text-right has-text-left-mobile pt-6">
                                    <div className="pb-6">
                                        <p className="is-size-5 has-text-weight-bold has-text-info">Filed under:</p>
                                        <p>{timescale ? timescale : 'All'}</p>
                                    </div>
                                    
                                    <div className="pb-6">
                                        <p className="is-size-5 has-text-weight-bold has-text-info">Tags:</p>
                                        <p>{subjectTags}</p>
                                    </div>
                                    
                                    <div className="pb-6">
                                        <p className="is-size-5 has-text-weight-bold has-text-info">Lesson Type:</p>
                                        <p>{type ? type.join(', ') : 'None'}</p>
                                    </div>
                                </div>
                            </div>
                    }
                </div>

                <ul className="columns" style={{flexWrap: 'wrap'}}>
                    {
                        images.map(({fluid, description, file}, index) => {
                            if(fluid){
                                return <li key={index} className="column is-one-third my-3">
                                            <figure className='image'>
                                                <Img 
                                                    fluid={fluid} 
                                                    alt={description}
                                                    imgStyle={{border: '0.25px dashed #b1799f'}} 
                                                />
                                            </figure>
                                        </li>
                            } else {
                                return <li key={index} className="column is-one-third my-3">
                                            <video controls>
                                                <track kind="captions" />
                                                <source src={file.url} type="video/mp4" />
                                            </video>
                                        </li>
                            }
                        })
                    }
                </ul>

                <div className="column is-10 is-size-4 has-text-justified">
                    <h1 className="py-5 has-text-info">Lesson Text</h1>
                    {lessonBody}
                </div>
            </section>

                {/* <section className="columns">
                    <ul className="column">
                        {
                            images.map(({fluid, description, file}, index) => {
                                if(fluid){
                                    return <li key={index} className="my-3">
                                                <figure className='image'>
                                                    <Img 
                                                        fluid={fluid} 
                                                        alt={description}
                                                        imgStyle={{border: '0.25px dashed #b1799f'}} 
                                                    />
                                                </figure>
                                            </li>
                                } else {
                                    return <li key={index} className="my-3">
                                                <video controls>
                                                    <track kind="captions" />
                                                    <source src={file.url} type="video/mp4" />
                                                </video>
                                            </li>
                                }
                            })
                        }
                    </ul>

                    <div className="column">
                        {lessonBody}
                    </div>
                </section>
            </section> */ }
        </Layout>
    )
}

export default Lesson
